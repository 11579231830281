import React from "react";

export default () => (
  <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <div className="w-full font-bold text-l text-gray-800 px-6">
        Projektverktyg
      </div>
      <p className="text-gray-800 text-sm px-6 mb-5">
        Säkerställer att samtliga åtaganden i ett projekt blir utförda m.h.a.
        Färdiga projektmallar och om dessa åtaganden ej blir utförda i tid,
        påminner respektive ägare om dess ärenden.<br></br>
        <br></br>
        Ex:
        <ul className="text-gray-800 text-sm px-6 mb-5 list-disc">
          <li>Garantidokumentation</li>
          <li>Kontrollrapporter</li>
          <li>Relationshandlingar </li>
        </ul>
      </p>
    </div>
  </div>
);
