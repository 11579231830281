import React from "react";

export default () => (
  <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <div className="w-full font-bold text-l text-gray-800 px-6">
        Ritningar
      </div>
      <p className="text-gray-800 text-sm px-6 mb-5">
        Möjliggör uppladdning av anläggningens ritningar.
        <br></br>
        <br></br>
        Ex:
        <ul className="text-gray-800 text-sm px-6 mb-5 list-disc">
          <li>DWG, PDF m.fl.</li>
          <li>
            Hantering av versioner
            <br></br>
            och revisioner.
          </li>
          <li>Möjlighet att validera systemets data mot ritningens innehåll. </li>
        </ul>
      </p>
    </div>
  </div>
);
