import React from "react";

import Forms from "./Forms.js";
import Documents from "./Documents.js";
import Inspection from "./Inspection.js";
import Authorities from "./Authorities.js";
import Drawings from "./Drawings.js";
import Inventory from "./Inventory";
import ProductCatalog from "./ProductCatalog.js";
import ImageStore from "./ImageStore.js";
import ProjectTool from "./ProjectTool.js";
import ErrorReport from "./ErrorReport.js";


export default () => (
  <div>
    <section className="bg-white py-8">
    <p className="font-bold text-l text-center">SAM:s olika moduler:</p>
      <div className="container mx-auto flex flex-wrap pt-4 pb-3">
        <Forms />
				<Authorities />
				<Inspection />
        <ImageStore />
        <Documents />
        <ErrorReport />
        <ProjectTool />
        <Drawings />
        <ProductCatalog />
        <Inventory />
      </div>
    </section>
  </div>
);


