import React from "react";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/SEO";
import Header from "../../components/layout/Header.js";
import SiteTitleQuery from "../../components/SiteTitleQuery";
import SamImage from "../../images/inHouseImages/samImage.jpg";
import Sam1Small from "../../images/sam/sam1Small.png";
import Sam2Small from "../../images/sam/sam2Small.png";
import SamModuleAll from "../../components/samModules/SamModuleAll.js";
// import ProfileCard from "../../components/ProfileCard.js";
// import Max from "../../images/profiles/Namn.jpg";

function Sam() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="SAM"
            keywords={[`molnbaserad`, `arbetsflöden`, `automatisera`, `myndighetsrapportering`, `registervård`, 
            `moduler`, `modulbaserat`, `anläggningar`, `cad`, `dokumentation`, `blanketter`, `tillsyn`, `egenkontroll`, `projektverktyg`, `felanmälan`, `ritningar`,]}
          />
          <Header data={data} />

          <div className="container mx-auto flex flex-col md:flex-row items-center my-8 md:my-10 justify-center">
            <div className="sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-3xl rounded overflow-hidden shadow">
              <img
                className="w-full"
                src={SamImage}
                alt="developer looking at code"
              ></img>
              <div className="px-6 py-4">
                <div className="font-bold text-l mb-2">SAM</div>
                <p className="text-gray-700 text-base">
                  Sites, Assets & Maintenance eller SAM är vårt modulära
                  molnbaserade system för hantering av anläggningar och dess
                  tillgångar. SAM möjliggör för er att digitalisera era
                  arbetsflöden i ett modernt, effektivt och säkert system.
                  Perfekt för bolag som vill effektivisera tid och resurser
                  genom att automatisera processer, myndighetsrapportering och
                  registervård.
                </p>
                <img
                  className="w-full my-8"
                  src={Sam1Small}
                  alt="SAM example 1"
                ></img>
                <p className="text-gray-700 text-base">
                  Som ett av de mest flexibla och konfigurerbara systemen på
                  marknaden kan vi skräddarsy SAM:s funktioner och moduler till
                  att passa just er verksamhet både nu och i framtiden.
                  <br></br>
                  <br></br>
                  SAM är designat för desktop och tablets men är även anpassat
                  för smartphones. Det är ett helt molnbaserat system som
                  möjliggör hög mobilitet, flexibilitet och säkerhet för våra
                  användare . Ett kraftfullt person- och behörighetsregister gör
                  så att rätt användare ser rätt saker vid rätt tillfälle.
                  <br></br>
                  Vi erbjuder även flera olika sätt att integrera med SAM
                  exempelvis mot ert affärssystem eller data-warehouse.
                </p>
                <img
                  className="w-full my-8"
                  src={Sam2Small}
                  alt="SAM example 2"
                ></img>
                <p>
                  SAM och dess olika moduler gör det möjligt för oss och våra
                  kunder att följa upp status på anläggningar och projekt samt
                  planera prospektering, utveckling och skötsel av anläggningar
                  samt att de olika regelverken följs.
                </p>
                <br></br>
                <br></br>

                <SamModuleAll />

                {/* <ProfileCard 
                  profile={Max}
                  name="Max Knutas"
                  email="mk@psksyd.com"
                /> */}
              </div>
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default Sam;
