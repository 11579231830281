import React from "react";

export default () => (
  <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <div className="w-full font-bold text-l text-gray-800 px-6">
        Fotogalleri
      </div>
      <p className="text-gray-800 text-sm px-6 mb-5">
        Central lagringsplats för en anläggnings alla fotografier. 
        Filtrerbart på person/företag, datum, kategori.
      </p>
    </div>
  </div>
);
