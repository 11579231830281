import React from "react";

export default () => (
  <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <div className="w-full font-bold text-l text-gray-800 px-6">
        Egenkontroll / Tillsyner
      </div>
      <p className="text-gray-800 text-sm px-6 mb-5">
        Konfigurerbara checklistor som kan riktas mot en speciell användargrupp
        och med konfigurerbara interval.
        <br></br>
        <br></br>
        Ex:
        <ul className="text-gray-800 text-sm px-6 mb-5 list-disc">
          <li>Dagliga tillsyner</li>
          <li>Säkerhetskontroller</li>
          <li>Gasåteföringskontroller</li>
        </ul>
        Har inbyggd avvikelsehantering och automatisk uppföljning.
      </p>
    </div>
  </div>
);
