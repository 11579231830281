import React from "react";

export default () => (
  <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <div className="w-full font-bold text-l text-gray-800 px-6">
        Blanketter
      </div>
      <p className="text-gray-800 text-sm px-6 mb-5">
        Digitalt konfigurerbart blankettsystem med möjlighet att skicka iväg
        blanketten digitalt efter färdigställande. Stöder enklare digital
        signering.
        <br></br>
        <br></br>
        Ex:
        <ul className="text-gray-800 text-sm px-6 mb-5 list-disc">
          <li>Polisrapporter</li>
          <li>Myndighetsansökningar</li>
          <li>Skadeanmälningar</li>
          <li>Årsrapporter</li>
        </ul>
      </p>
    </div>
  </div>
);
