import React from "react";

export default () => (
  <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <div className="w-full font-bold text-l text-gray-800 px-6">
        Felanmälan
      </div>
      <p className="text-gray-800 text-sm px-6 mb-5">
        En kraftfull felanmälningsmodul som möjliggör felanmälningar från varje
        anläggning och knyta varje specifik objekt det är fel på.
        <br></br>
        <br></br>
        Konfigurerbara feltyper
        <br></br>
        <br></br>
        <ul className="text-gray-800 text-sm px-6 mb-5 list-disc">
          <li>Hanterar prioritet</li>
          <li>Automatisk Arbetsorderhantering</li>
          <li>Integration via separat API för 3e part </li>
          <li>Kommunikation och notifikation på varje arbetsorder </li>
        </ul>
      </p>
    </div>
  </div>
);
